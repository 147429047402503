<template>
  <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.761719 18V16.9375C0.761719 12.8299 4.09161 9.5 8.19923 9.5C12.3069 9.5 15.6367 12.8299 15.6367 16.9375V18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.19922 9.50001C10.5464 9.50001 12.4492 7.59718 12.4492 5.25001C12.4492 2.90279 10.5464 1 8.19922 1C5.85201 1 3.94922 2.90279 3.94922 5.25001C3.94922 7.59718 5.85201 9.50001 8.19922 9.50001Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProfileSvg'
})
</script>

<style scoped>

</style>
