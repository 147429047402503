<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5133 18.0002C17.2174 18.0002 17.7883 17.4293 17.7883 16.7252C17.7883 16.0211 17.2174 15.4502 16.5133 15.4502C15.8091 15.4502 15.2383 16.0211 15.2383 16.7252C15.2383 17.4293 15.8091 18.0002 16.5133 18.0002Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.01328 18.0002C8.71742 18.0002 9.28828 17.4293 9.28828 16.7252C9.28828 16.0211 8.71742 15.4502 8.01328 15.4502C7.30912 15.4502 6.73828 16.0211 6.73828 16.7252C6.73828 17.4293 7.30912 18.0002 8.01328 18.0002Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.18672 2.7H18.6367L16.9367 12.05H5.88672L4.18672 2.7ZM4.18672 2.7C4.04505 2.13333 3.33672 1 1.63672 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.9375 12.0498H5.88751H4.38366C2.86699 12.0498 2.0625 12.7138 2.0625 13.7498C2.0625 14.7858 2.86699 15.4498 4.38366 15.4498H16.5125" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CartSvg'
})
</script>

<style scoped>

</style>
