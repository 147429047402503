<template>
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7607 6.49895C19.7607 7.9496 19.2036 9.34296 18.209 10.3737C15.9193 12.747 13.6985 15.2218 11.3233 17.5091C10.7789 18.0258 9.91525 18.0069 9.39426 17.4669L2.55128 10.3737C0.482907 8.2296 0.482907 4.76829 2.55128 2.62427C4.63998 0.459177 8.04269 0.459177 10.1314 2.62427L10.3801 2.88209L10.6287 2.62442C11.6302 1.5858 12.9941 1 14.4188 1C15.8436 1 17.2074 1.58574 18.209 2.62427C19.2037 3.65505 19.7607 5.04833 19.7607 6.49895Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LikeSvg'
})
</script>

<style scoped>

</style>
