<template>
  <section id="disclaimer" class="section p-0-portable">
    <div class="container">
      <div class="disclaimer">
        <h2 class="disclaimer__title">Дисклеймер 18+</h2>
        <p class="disclaimer__description">Фотографии, видео и тексты, размещённые на сайте notcatart.com не преследуют цели возбуждения ненависти, вражды и какого-либо уничижения достоинства человека/людей по половому и гендерному признаку, расовому или этническому происхождению, религиозному вероисповеданию, из-за принадлежности к какой-либо культурной группе. Так же материалы данного сайта не содержат в себе даже отдалённого намёка на призыв к насилию или вовлечение в какие-либо идейные течения, запрещённые на территории РФ. Цели и задачи notcatart.com чисто творческие и немного коммерческие, и не более того.</p>
      </div>
    </div>
  </section>

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DisclaimerSection'
})
</script>

<style scoped>

</style>
