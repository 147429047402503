<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 13.75L18 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 8.28572C1 12.3095 4.26193 15.5714 8.28572 15.5714C10.3011 15.5714 12.1254 14.7531 13.4444 13.4307C14.7588 12.1128 15.5714 10.2942 15.5714 8.28572C15.5714 4.26193 12.3095 1 8.28572 1C4.26193 1 1 4.26193 1 8.28572Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchSvg'
})
</script>

<style scoped>

</style>
